import { render, staticRenderFns } from "./Ic4gOfcsView.vue?vue&type=template&id=0f62d1b9&scoped=true"
import script from "./Ic4gOfcsView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic4gOfcsView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f62d1b9",
  null
  
)

export default component.exports